 * {
  margin: 0;
  padding: 0;
} 

.landing {
  background: url("https://www.evangadi.com/themes/humans/assets/images/misc/bg-svg-f.svg")
    no-repeat;
  background-size: cover;
  background-color: #f8f8f8;
  width: 100%;
  height: 600px;
 
}


